<template>
<div id="footer-menu" class="footer-menu-container">
    <div class="menu">
        <router-link to="/" title="Meredith Schlosser" class="menu-item">
            Home
        </router-link>
        <div class="dropdown">
            <a class="menu-item" tabindex="3">About+</a>                
            <div class="dropdown-content">
                <router-link to="/about" alt="About">
                    About Meredith Schlosser
                </router-link>
                <router-link to="/team" alt="Team">
                    Meredith Schlosser Team
                </router-link>
                <a href="https://www.bhhscaproperties.com" target="blank" alt="BHHS">BHHS</a>
            </div>
        </div>
        <router-link to="/listings" title="Listings" class="menu-item">
            Listings
        </router-link>
        <router-link to="/sold" title="Sold" class="menu-item">
            Sold
        </router-link>
        <router-link to="/exclusive-guide" alt="Playa Vista Guide" title="Playa Vista Guide" class="menu-item">
            Playa Vista Guide
        </router-link>
        <router-link to="/testimonials" title="Testimonials for Meredith Schlosser" class="menu-item">
            Testimonials
        </router-link>
        <router-link to="/press" title="Press" class="menu-item">
            Press
        </router-link>
        <router-link to="/contact" title="Contact Meredith Schlosser" class="menu-item">
            Contact
        </router-link>
    </div>
    <div class="logo-container">
        <a class="desktop-only" href="https://www.bhhscaproperties.com" target="_blank">
            <img src="/images/logo-meredith-bhhs.svg" alt="Meredith Logo">
        </a>
        <img class="mobile-only" src="/images/logo-meredith-white.svg" alt="Meredith Logo">
        <a class="mobile-only bottom-image" href="https://www.bhhscaproperties.com" target="_blank" alt="BHHS Logo">
            <img class="mobile-only bottom-image" src="/images/logo-bhhs.svg">
        </a>
    </div>
    <div class="social-container">
        <a href="https://www.instagram.com/meredithrealestate/" target="_blank" aria-label="Go to Meredith Instagram">
            <font-awesome-icon class="social-icon" :icon="['fab', `instagram`]" />
        </a>
        <a href="https://www.linkedin.com/in/meredith-schlosser-1167234/"  target="_blank" aria-label="Go to Meredith LinkedIn">
            <font-awesome-icon class="social-icon" :icon="['fab', `linkedin`]" />
        </a>
        <a href="https://www.facebook.com/meredithschlosserrealestate/"  target="_blank" aria-label="Go to Meredith Facebook">
            <font-awesome-icon class="social-icon" :icon="['fab', `facebook-square`]" />
        </a>         
    </div>
    <div class="credit">
        <p>
            Copyright © {{year}} - Meredith Schlosser - (310) 923-5811<br>
            11677 San Vicente Blvd #307, Los Angeles, CA 90049<br>
            <br>
            Site managed by <a href="https://www.austinrobbins.com/">Austin Robbins Agency</a>
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: "FooterContent",
    data(){
        return {
            email: "info@paulsalazargroup.com",
            telephone: "310.492.0737",
            year: new Date().getFullYear(),
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: $mobile) {
    .logo-container{
        gap: 0;
    }
}
</style>
